<p-dialog
  [(visible)]="visible"
  class="vacation-register-modal"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [style]="{ width: '55%', height: '90vh' }"
>
  <div class="form-container">
    <!-- Primera columna -->
    <div class="column">
      <div class="label-vacation">
        <h2>Especifica el periodo</h2>
      </div>
      <div class="field">
        <label for="empresa" class="font-semibold">Empresa</label>
        <p-autoComplete
          id="empresa"
          [(ngModel)]="selectedEmpresa"
          [suggestions]="filteredOptions"
          (completeMethod)="filterOptions($event, 'empresa')"
          (input)="onChangeInput($event, 'empresa')"
          [dropdown]="true"
          [forceSelection]="true"
          [placeholder]="'Selecciona una empresa...'"
          (onSelect)="onSelect($event, 'empresa')"
          [style]="{ width: '100%' }"
          [field]="'text'"
        ></p-autoComplete>
        <div
          *ngIf="!selectedEmpresa && hasInteracted['empresa']"
          class="error-message"
          style="color: red; font-size: 0.875rem; margin-top: 5px"
        >
          Este campo es obligatorio.
        </div>
      </div>
      <div class="field">
        <label for="colaborador" class="font-semibold">Colaborador</label>
        <p-autoComplete
          id="colaborador"
          [(ngModel)]="selectedColaborador"
          [suggestions]="filteredOptions"
          (completeMethod)="filterOptions($event, 'colaborador')"
          (input)="onChangeInput($event, 'colaborador')"
          [dropdown]="true"
          [forceSelection]="true"
          [placeholder]="'Selecciona un colaborador...'"
          (onSelect)="onSelect($event, 'colaborador')"
          [style]="{ width: '100%' }"
          [field]="'text'"
        ></p-autoComplete>
        <div
          *ngIf="!selectedColaborador && hasInteracted['colaborador']"
          class="error-message"
          style="color: red; font-size: 0.875rem; margin-top: 5px"
        >
          Este campo es obligatorio.
        </div>
      </div>

      <!-- Selección de Fechas -->
      <div class="calendar-row">
        <div class="calendar-item">
          <label for="startDate">Inicio de vacaciones</label>
          <p-calendar
            id="startDate"
            [(ngModel)]="startDate"
            [icon]="'pi pi-pencil'"
            [showIcon]="true"
            (ngModelChange)="onStartDateChange($event)"
            [maxDate]="maxStartDate"
            dateFormat="dd/mm/yy"
            (onBlur)="validateField('startDate')"
          ></p-calendar>
        </div>
        <div class="calendar-item">
          <label for="endDate">Fin de vacaciones</label>
          <p-calendar
            id="endDate"
            [(ngModel)]="endDate"
            [icon]="'pi pi-pencil'"
            [showIcon]="true"
            (ngModelChange)="onEndDateChange($event)"
            [minDate]="startDate"
            [maxDate]="maxEndDate"
            dateFormat="dd/mm/yy"
            (onBlur)="validateField('endDate')"
          ></p-calendar>
        </div>
      </div>

      <!-- Tipo de Vacaciones -->
      <div class="p-field-autocomplete">
        <label for="vacationType">Tipo de vacaciones</label>
        <p-autoComplete
          id="vacationType"
          [(ngModel)]="selectedVacationType"
          [suggestions]="filteredVacationTypes"
          (completeMethod)="filterVacationTypes($event)"
          (input)="onChangeInput($event, 'tipoVacacion')"
          [dropdown]="true"
          [forceSelection]="true"
          placeholder="Seleccione el tipo"
          (onSelect)="onSelect($event, 'tipoVacacion')"
          [style]="{ width: '100%' }"
          [field]="'text'"
        ></p-autoComplete>
        <div
          *ngIf="!selectedVacationType && hasInteracted['tipoVacacion']"
          class="error-message"
          style="color: red; font-size: 0.875rem; margin-top: 5px"
        >
          Este campo es obligatorio.
        </div>
      </div>

      <!-- Iconos de Vacaciones -->
      <div class="vacation-icons">
        <div
          class="vacation-option"
          [ngClass]="{
            active: selectedOptionKey == '1' || selectedOptionKey == '3',
            inactive: selectedOptionKey != '1' && selectedOptionKey != '3'
          }"
        >
          <img src="/assets/svg/vacaciones.svg" alt="" />
          <div class="vacation-bottom">
            <span>
              Vacaciones por ley
              <i
                class="pi pi-info-circle"
                (click)="
                  openTooltipModal(
                    'Vacaciones por ley',
                    'Acumulas 1.25 días por mes laborado'
                  )
                "
              ></i>
            </span>
            <small [ngClass]="{ highlight: selectedOptionKey == '1' || selectedOptionKey == '3' }">
              Tienes {{ accumulatedDays - usedDaysLaw }} días acumulados
            </small>
          </div>
        </div>

        <div
          class="vacation-option"
          [ngClass]="{
            active: selectedOptionKey == '2' || selectedOptionKey == '3',
            inactive: selectedOptionKey != '2' && selectedOptionKey != '3'
          }"
        >
          <img src="/assets/svg/reloj.svg" alt="" />
          <div class="vacation-bottom">
            <span>
              Bono vacacional
              <i
                class="pi pi-info-circle"
                (click)="
                  openTooltipModal(
                    'Bono vacacional',
                    'Por año laborado, este bono es entregado anualmente según las políticas de la empresa'
                  )
                "
              ></i>
            </span>
            <small [ngClass]="{ highlight: selectedOptionKey == '2' || selectedOptionKey == '3' }">
              Tienes {{ accumulatedBonusDays - usedBonusDays }} días acumulados
            </small>
          </div>
        </div>
      </div>

      <!-- Mensaje Opcional -->
      <div class="field">
        <label for="message">Escriba un mensaje (Opcional)</label>
        <div class="textarea-container">
          <textarea
            id="message"
            rows="5"
            [(ngModel)]="message"
            placeholder="Escriba su mensaje aquí..."
            maxlength="200"
          ></textarea>
          <small>200/{{ message.length || 0 }}</small>
        </div>
      </div>

      <!-- Botón de Enviar -->
      <div class="submit-button">
        <button
          pButton
          type="button"
          label="Crear solicitud"
          [disabled]="isDisabled"
          (click)="onRegister()"
        ></button>
      </div>
    </div>

    <!-- Segunda columna -->
    <div class="column">
      <!-- Calendario -->
      <div class="calendar-container">
        <p-calendar
          [(ngModel)]="selectedDates"
          selectionMode="range"
          [readonlyInput]="true"
          inline="true"
          [minDate]="minDate"
          [maxDate]="maxEndDate"
          (onSelect)="onRangeSelect()"
        />
      </div>

      <div class="total-days-container">
        <h3>Total de días: {{ totalDays }} {{ getDayLabel(totalDays) }}</h3>
        <p>Expira en: {{ endDate | date : "MMMM d, y" }}</p>
        <hr />
        <small>
          Los días no utilizados de hasta {{ transferableDays }}
          {{ getDayLabel(transferableDays) }} se trasladará al siguiente.
        </small>
      </div>

      <div class="field">
        <label for="status" class="font-semibold">Status de solicitud</label>
        <p-autoComplete
          id="status"
          [(ngModel)]="selectedstatusSolicitude"
          [suggestions]="filteredVacationTypes"
          (completeMethod)="filterOptions($event, 'status')"
          (input)="onChangeInput($event, 'status')"
          [dropdown]="true"
          [forceSelection]="true"
          placeholder="Seleccione el tipo"
          (onSelect)="onSelect($event, 'status')"
          [style]="{ width: '100%' }"
          [field]="'text'"
          [disabled]="true"
        ></p-autoComplete>
      </div>
    </div>
  </div>
</p-dialog>
