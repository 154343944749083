import { Component } from '@angular/core';
import { IComboBase } from '../interfaces/base/base.interface';
import { VacationService } from './services/vacation.service';
import { monthOptions } from '../utils/helpers';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CompanyService } from '../services/company.service';

@Component({
  selector: 'app-vacation',
  templateUrl: './vacation.component.html',
  styleUrls: ['./vacation.component.scss'],
})
export class VacationComponent {
dataSource: any[] = [];
  mostrar: boolean = false;
  dataToEdit: any = null;
  mostrarModalEditar: boolean = false;
  mostrarModalDetalle: boolean = false;
  dataDetail: any = null;
  vacationId: number = 0;

  // Opciones del combo
  monthOptions: IComboBase[] =  [{ key: '00', text: 'Todos' }, ...monthOptions];
  currentMonth: IComboBase | null = null;

  isLoading: boolean = true;

  // Término de búsqueda
  searchTerm = '';

  // Mes seleccionado
  selectedMonth: IComboBase | null = null;
  userData: any = null;

  headerClasses: { [key: string]: string } = {
    '1': 'header-keola',
    '2': 'header-inresort',
    '3': 'header-inclub',
    '4': 'header-ribera',
    '5': 'header-keolafood',
    '6': 'header-valle',
  };

  currentHeaderClass: string = 'header-keola';

  selectedDocument: SafeResourceUrl | null = null;
  constructor(
    private vacationService: VacationService,
    private companyService: CompanyService,
    private sanitizer: DomSanitizer
  ) {
    this.currentMonth = this.getCurrentMonth();
    this.selectedMonth = this.currentMonth; 
  }

  tabs: IComboBase[] = [];
  selectedTabKey: string | null = null;

  displayedColumns = [
    { key: 'persona', header: 'Empleado' },
    { key: 'employeeDocumentNumber', header: 'DNI' },
    { key: 'created', header: 'Solicitud' },
    { key: 'breakStartDate', header: 'Inicio' },
    { key: 'breakEndDate', header: 'Fin' },
    { key: 'solicitedDays', header: 'Total días' },
    { key: 'typeVacationName', header: 'Tipo' },
    { key: 'estado', header: 'Status' },
  ];

  actionButtons = [
    {
      icon: 'pi pi-pen-to-square',
      color: '#ED672A',
      tooltip: 'Editar',
      action: (row: any) => {
        this.vacationId = row.solicitudId,
        this.dataToEdit = {
          ...row,
          empresa: this.tabs.find((tab) => tab.key === this.selectedTabKey),
        };
        this.mostrarModalEditar = true;
      },
      visible: (row: any) => true,
    },
    {
      icon: 'pi pi-eye',
      color: '#ED672A',
      tooltip: 'Ver',
      action: (row: any) => {
        this.dataDetail = {
          ...row,
          empresa: this.tabs.find((tab) => tab.key === this.selectedTabKey),
        };
        this.mostrarModalDetalle = true;
      },
      visible: (row: any) => true,
    },
  ];

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.tabs = data;
        this.selectedTabKey = this.tabs[0].key;
        this.onTabSelected(this.selectedTabKey);
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
        this.filterData();
      },
    });
  }

  onNewRegister(): void {
    this.mostrar = true;
  }

  onDialogClose() {
    this.mostrar = false; // Cierra el modal
  }

  onDialogDetailClose() {
    this.mostrarModalDetalle = false;
    this.dataDetail = null;
  }
  

  onMonthChange(selected: IComboBase): void {
    this.selectedMonth = selected;
    this.filterData(); // Aplicar filtro cuando cambia el mes
  }

  private getCurrentMonth(): IComboBase | null {
    const currentMonthKey = new Date().toLocaleDateString('en-GB', {
      month: '2-digit',
    });
    return (
      this.monthOptions.find((option) => option.key === currentMonthKey) || null
    );
  }

  onSearch(query: string): void {
    this.searchTerm = query;
    this.filterData(); // Aplicar filtro cuando cambia la búsqueda
  }

  filterData(): void {
    this.isLoading = true;
    const filters = {
      search: this.searchTerm,
      month: this.selectedMonth?.key == '00' ? null : this.selectedMonth?.key, // Si no hay mes seleccionado, enviar null
      companyId: this.selectedTabKey || null,
      typeSolicitudeId: 2
    };

    this.vacationService.postSolicitudesByCompany(filters).subscribe({
      next: (data: any[]) => {
        this.dataSource = data;
        this.isLoading = false;
      },
      error: (err: any) => {
        this.isLoading = false;
        console.error('Error al obtener los datos:', err);
      },
    });
  }

  onTabSelected(tabKey: string): void {
    this.selectedTabKey = tabKey;
    this.currentHeaderClass = this.headerClasses[tabKey] || 'header-keola';
    this.selectedDocument = null;
    this.filterData();
    // Puedes agregar lógica adicional aquí, como actualizar los datos mostrados
  }

  onRowSelected(row: any): void {
    if (row && row.paymentSlipUrl) {
      const uniqueUrl = `${row.paymentSlipUrl}?timestamp=${new Date().getTime()}`;
      this.selectedDocument = this.sanitizer.bypassSecurityTrustResourceUrl(uniqueUrl);
    } else {
      this.selectedDocument = null;
    }
  }
  
  onRegisterComplete(): void {
    this.filterData();
  }

  onEditComplete(): void {
    this.filterData();
  }

  onDeleteComplete(): void {
    this.filterData();
  }
}
