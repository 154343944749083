import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalInfoComponent {
  title: string = '';
  description: string = '';

  constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.title = this.config.data.title;
    this.description = this.config.data.description;
  }

  close() {
    this.ref.close();
  }
}
